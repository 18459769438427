<template>
  <div class="space-y-2">
    <inputs-label v-if="title" :for="id" :title="`${title} ${required ? '*' : ''}`"/>

    <select :id="id"
            :autofocus="autofocus"
            :name="name ?? id"
            :required="required"
            :value="value"
            class="w-full input pr-8"
            @change="emit('set', $event)">
      <option disabled selected value="" v-if="default_option_label || title">
        {{ default_option_label ?? `Select the ${title}` }}
      </option>

      <option v-for="item in items"
              :key="item.key"
              :selected="item.key == value"
              :value="item.key === null ? '' : item.key">
        {{ item.label }}
      </option>
    </select>
  </div>
</template>

<script setup>
defineProps({
  id: {
    type: String,
    required: true,
  },

  items: {
    type: Array,
    required: true,
  },

  title: {
    type: String,
    required: false,
  },

  autofocus: {
    type: Boolean,
    required: false,
  },

  name: {
    type: String,
    required: false,
  },

  value: {
    type: String,
    default: null,
  },

  required: {
    type: Boolean,
    required: false,
    default: false,
  },

  default_option_label: {
    type: String,
    required: false,
  },
})

const emit = defineEmits(['set'])
</script>
